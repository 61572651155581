import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layout"
import Posts from '../../components/posts/posts'
import Search from "../../components/search/search"
import SEO from "../../components/seo/seo"

class WordsPage extends React.Component {

  render() {
    const posts = this.props.data.allMarkdownRemark.nodes
    return (
      <Layout title='words'>
        <h1>reflections</h1>
        <SEO />
        <p>the translation of feelings and experiences into verbiage is a lossy process</p>
        {/* <Search
          posts={posts}
          localSearch={this.props.data.localSearchWords}
          placeholder='Search all posts...'
          prefix='words'
        /> */}
          <Posts data={posts} prefix={'words'} />
      </Layout>
    )
  }
}

export const query = graphql`
  query WordsPageQuery {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "words" }}}
    ) {
      nodes {
        fields {
          slug
          date
        }
        excerpt(pruneLength: 212)
        frontmatter {
          title
          tags
          cover
          date
        }
      }
    }

  }
`
    // localSearchWords {
    //   index
    //   store
    // }

export default WordsPage